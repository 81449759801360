import Vue from 'vue';
import i18n from '@/i18n';
import VueRouter from 'vue-router';
import DashboardLayout from '../Layout/DashboardLayout.vue';

// Dashboard pages
const Dashboard = () => import('../views/Dashboard.vue');
const Domains = () => import('../views/Domains.vue');
const Servers = () => import('../views/Servers.vue');

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: i18n.t('navigation.menu'),
        redirect: '/pages/dashboard',
        component: DashboardLayout,
        children: [
            {
                path: 'pages/dashboard',
                name: i18n.t('navigation.dashboard'),
                component: Dashboard,
                meta: {
                    groupName: 'Panel',
                },
            },
            {
                path: 'pages/domains',
                name: i18n.t('navigation.domains'),
                component: Domains,
                meta: {
                    groupName: 'Panel',
                },
            },
            {
                path: 'pages/servers',
                name: i18n.t('navigation.servers'),
                component: Servers,
                meta: {
                    groupName: 'Panel',
                },
            },
        ],
    },
];

const router = new VueRouter({
    routes,
});

export default router;
