export default {
    site: {
        name: 'EFRADO',
    },
    form: {
        rule: {
            required: 'To pole jest wymagane',
        },
        search: 'Wyszukaj',
        button: {
            cancel: 'Anuluj',
            confirm: 'Potwierdź',
        },
        select: {
            noDataAvailable: 'Brak dostępnych opcji',
        },
    },
    navigation: {
        menu: 'Menu',
        domains: 'Domeny',
        servers: 'Serwery',
        dashboard: 'Panel główny',
    },
    datatable: {
        loadingError: 'Wystąpił błąd podczas wczytywania',
        noDataAvailable: 'Brak danych do wyświetlenia',
        pagination: {
            rowsPerPage: 'Wierszy na stronę:',
        },
        action: {
            addNew: 'Dodaj Nowy',
            edit: 'Edytuj',
        },
        formHeader: {
            add: 'Dodaj',
            edit: 'Edytuj',
            new: 'Nowy',
            delete: 'Czy na pewno chcesz usunąć ten przedmiot?',
        },
    },
    domains: {
        table: {
            header: 'Lista domen',
            subheader: 'Zarządzaj listą',
            tableHeader: {
                name: 'Nazwa',
                ip: 'IP',
                status: 'Status',
                last24h: 'Ostatnie 24h',
                actions: 'Akcje',
            },
        },
    },
    servers: {
        table: {
            header: 'Lista serwerów',
            subheader: 'Zarządzaj listą',
            tableHeader: {
                host: 'Host',
                ip: 'IP',
                type: 'Typ',
                status: 'Status',
                last24h: 'Ostatnie 24h',
                actions: 'Akcje',
            },
        },
    },
    dashboard: {
        seeAllServers: 'Zobacz wszystkie serwery',
        seeAllDomains: 'Zobacz wszystkie domeny',
    },
};
