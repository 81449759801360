<template>
    <v-navigation-drawer
        width="100%"
        height="calc(100% - 2rem)"
        fixed
        app
        floating
        :expand-on-hover="mini"
        :value="drawer"
        :right="$vuetify.rtl"
        class="my-4 ms-4 border-radius-xl"
        :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
        :data-color="sidebarColor"
        :data-theme="sidebarTheme"
    >
        <v-list-item class="pa-0">
            <v-list-item-content class="pa-0">
                <v-list-item-title class="title d-flex align-center mb-0">
                    <div
                        class="v-navigation-drawer-brand pa-5 d-flex align-center"
                    >
                        <span class="ms-2 font-weight-bold text-sm">{{
                            $t('site.name')
                        }}</span>
                    </div>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <hr
            class="horizontal mb-0"
            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
        />

        <v-list nav dense>
            <v-list-group
                :ripple="false"
                v-for="item in items"
                :key="item.title"
                v-model="item.active"
                append-icon="fas fa-angle-down"
                class="pb-1 mx-2"
                active-class="item-active"
            >
                <template v-slot:activator>
                    <v-list-item-icon class="me-2 align-center">
                        <i class="material-icons-round opacity-10">{{
                            item.action
                        }}</i>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title
                            v-text="item.title"
                            class="ms-1"
                        ></v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item
                    :ripple="false"
                    link
                    class="mb-1 no-default-hover px-0"
                    :class="child.items ? 'has-children' : ''"
                    v-for="child in item.items"
                    :key="child.title"
                    :to="child.link"
                >
                    <div
                        class="w-100 d-flex align-center pa-2 border-radius-lg"
                    >
                        <span
                            class="v-list-item-mini"
                            v-text="child.prefix"
                        ></span>

                        <v-list-item-content
                            class="ms-6 ps-1"
                            v-if="!child.items"
                        >
                            <v-list-item-title
                                v-text="child.title"
                                @click="listClose($event)"
                            ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-content
                            class="ms-6 ps-1 py-0"
                            v-if="child.items"
                        >
                            <v-list-group
                                prepend-icon=""
                                :ripple="false"
                                sub-group
                                no-action
                                v-model="child.active"
                            >
                                <template v-slot:activator>
                                    <v-list nav dense class="pa-0">
                                        <v-list-group
                                            :ripple="false"
                                            append-icon="fas fa-angle-down me-auto ms-1"
                                            active-class="item-active"
                                            class="mb-0"
                                        >
                                            <template
                                                v-slot:activator
                                                class="mb-0"
                                            >
                                                <v-list-item-content
                                                    class="py-0"
                                                >
                                                    <v-list-item-title
                                                        v-text="child.title"
                                                    ></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </v-list-group>
                                    </v-list>
                                </template>

                                <v-list-item
                                    v-for="child2 in child.items"
                                    :ripple="false"
                                    :key="child2.title"
                                    :to="child2.link"
                                    @click="listClose($event)"
                                >
                                    <span
                                        class="v-list-item-mini"
                                        v-text="child2.prefix"
                                    ></span>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="child2.title"
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-group>
                        </v-list-item-content>
                    </div>
                </v-list-item>
            </v-list-group>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
export default {
    name: 'drawer',
    props: {
        drawer: {
            type: Boolean,
            default: null,
        },
        sidebarColor: {
            type: String,
            default: 'success',
        },
        sidebarTheme: {
            type: String,
            default: 'dark',
        },
    },
    data() {
        return {
            mini: false,
            togglerActive: false,
            items: [
                {
                    action: 'dashboard',
                    active: true,
                    title: this.$t('navigation.menu'),
                    items: [
                        {
                            title: this.$t('navigation.dashboard'),
                            prefix: 'P',
                            link: '/pages/dashboard',
                        },
                        {
                            title: this.$t('navigation.servers'),
                            prefix: 'S',
                            link: '/pages/servers',
                        },
                        {
                            title: this.$t('navigation.domains'),
                            prefix: 'D',
                            link: '/pages/domains',
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        listClose(event) {
            let items;
            let headers;
            let groups;
            let currentEl;

            if (
                document.querySelectorAll(
                    '.mb-0.v-list-item.v-list-item--link.item-active'
                )
            ) {
                items = document.querySelectorAll(
                    '.mb-0.v-list-item.v-list-item--link.item-active'
                );
            }

            if (
                document.querySelectorAll(
                    '.mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active'
                )
            ) {
                headers = document.querySelectorAll(
                    '.mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active'
                );
            }

            if (
                document.querySelectorAll(
                    '.mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active'
                )
            ) {
                groups = document.querySelectorAll(
                    '.mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active'
                );
            }

            if (
                event.target.closest(
                    '.mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group'
                )
            ) {
                currentEl = event.target.closest(
                    '.mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group'
                );
            }

            if (items != null) {
                for (var i = 0; i < items.length; i++) {
                    items[i].classList.remove('item-active');
                }
            }

            if (headers != null) {
                for (var j = 0; j < headers.length; j++) {
                    headers[j].classList.remove(
                        'v-list-item--active',
                        'item-active',
                        'primary--text'
                    );
                    headers[j].setAttribute('aria-expanded', false);
                }
            }

            if (groups != null) {
                for (var k = 0; k < groups.length; k++) {
                    groups[k].classList.remove(
                        'v-list-group--active',
                        'primary--text'
                    );
                }
            }

            if (event.target.closest('.mb-0.v-list-item.v-list-item--link')) {
                event.target
                    .closest('.mb-0.v-list-item.v-list-item--link')
                    .classList.add('item-active');
            }

            if (currentEl != null) {
                currentEl
                    .querySelector('.v-list-group__header')
                    .classList.add('v-list-item--active', 'item-active');
            }
        },
    },
};
</script>
