// i18n setup
import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

// import locales
import pl from './pl';
import en from './en';

// Create VueI18n instance with options
export default new VueI18n({
    locale: 'pl', // set locale
    messages: { pl, en }, // set locale messages
});
